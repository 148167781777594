// Export All Actions In Over All Foulder //

/* Sample List  */
export const GET_SAMPLE_LIST_DATA = 'GET_SAMPLE_LIST_DATA';

// registration stepdata
export const STEP_TWO_DATA = 'STEP_TWO';

/* landing page */
export const GET_DISTRICTS_DATA = 'GET_DISTRICTS_DATA';
export const GET_GLOBAL_LANGUAGE = 'GET_GLOBAL_LANGUAGE';

/* schedule */
export const GET_SCHEDULES = 'GET_SCHEDULES';

/* Studnt */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_USER_LOGOUT = 'LOGIN_USER_LOGOUT';
export const LOGIN_USER_LOGOUT_SUCCESS = 'LOGIN_USER_LOGOUT_SUCCESS';
export const LOGIN_USER_LOGOUT_ERROR = 'LOGIN_USER_LOGOUT_ERROR';
export const GET_STUDENTS = 'GET_STUDENTS';
export const GET_CHALLENGE_QUESTIONS = 'GET_CHALLENGE_QUESTIONS';
export const GET_CHALLENGE_SUBMITTED_DATA = 'GET_CHALLENGE_SUBMITTED_DATA';
export const GET_STUDENT_BADGES = 'GET_STUDENT_BADGES';
export const UPDATE_STUDENT_STATUS = 'UPDATE_STUDENT_STATUS';
export const GET_STUDENTS_LIST_SUCCESS = 'GET_STUDENTS_LIST_SUCCESS';
export const GET_STUDENTS_LANGUAGE = 'GET_STUDENTS_LANGUAGE';
export const GET_STUDENTS_LIST_ERROR = 'GET_STUDENTS_LIST_ERROR';
export const GET_STUDENT = 'GET_STUDENT';
export const GET_DISTRICTS = 'GET_DISTRICTS';
export const GET_STATES = 'GET_STATES';
export const GET_FETCHDIST = 'GET_FETCHDIST';
export const GET_PINCODES = 'GET_PINCODES';
export const GET_ATLCODES = 'GET_ATLCODES';

export const GET_FETCH_INST_DIST = 'GET_FETCH_INST_DIST';
export const GET_INST_BLOCK = 'GET_INST_BLOCK';
export const GET_INST_TALUK = 'GET_INST_TALUK';
export const GET_PLACE = 'GET_PLACE';

export const GET_STUDENT_DASHBOARD_STATUS = 'GET_STUDENT_DASHBOARD_STATUS';
export const GET_STUDENT_DASHBOARD_CHALLENGES_STATUS =
    'GET_STUDENT_DASHBOARD_CHALLENGES_STATUS';
export const GET_STUDENT_DASHBOARD_TEAMPROGRESS =
    'GET_STUDENT_DASHBOARD_TEAMPROGRESS';
export const GET_STUDENT_DASHBOARD_TUTORIALS =
    'GET_STUDENT_DASHBOARD_TUTORIALS';
export const SET_PRESURVEY_STATUS = 'SET_PRESURVEY_STATUS';
export const SET_POSTSURVEY_STATUS = 'SET_POSTSURVEY_STATUS';
export const SET_FILE_SUCCESS = 'SET_FILE_SUCCESS';

/* team */
export const GET_MYTEAM = 'GET_MYTEAM';
export const GET_MYTEAM_SUCCESS = 'GET_MYTEAM_SUCCESS';
export const GET_MYTEAM_ERROR = 'GET_MYTEAM_ERROR';

/* reports */
export const GET_SURVEY_REPORT = 'GET_SURVEY_REPORT';
export const GET_MRNTOR_REG_STATUS_REPORT = 'GET_MRNTOR_REG_STATUS_REPORT';
export const GET_MENTOR_REPORT = 'GET_MENTOR_REPORT';

// Mentors //
export const GET_TEACHERS = 'GET_TEACHERS';
export const MENTORS_CREATE = 'MENTORS_CREATE';
export const MENTORS_LANGUAGE = 'MENTORS_LANGUAGE';
export const MENTORS_CREATE_SUCCESS = 'MENTORS_CREATE_SUCCESS';
export const MENTORS_CREATE_ERROR = 'MENTORS_CREATE_ERROR';
export const MENTORS_LIST = 'MENTORS_LIST';
export const MENTORS_LIST_SUCCESS = 'MENTORS_LIST_SUCCESS';
export const MENTORS_LIST_ERROR = 'MENTORS_LIST_ERROR';
export const MENTORS_DELETE = 'MENTORS_DELETE';
export const MENTORS_DELETE_SUCCESS = 'MENTORS_DELETE_SUCCESS';
export const MENTORS_DELETE_ERROR = 'MENTORS_DELETE_ERROR';
export const MENTORS_GET_SUPPORT_TICKETS = 'MENTORS_GET_SUPPORT_TICKETS';
export const MENTORS_CREATE_SUPPORT_TICKETS = 'MENTORS_CREATE_SUPPORT_TICKETS';
export const MENTORS_GET_SUPPORT_TICKETS_BY_ID =
    'MENTORS_GET_SUPPORT_TICKETS_BY_ID';
export const MENTORS_GET_SUPPORT_TICKETS_RESPONSES_BY_ID =
    'MENTORS_GET_SUPPORT_TICKETS_RESPONSES_BY_ID';
export const GET_TEACHERS_PRESURVEY = 'GET_TEACHERS_PRESURVEY';
export const GET_TEACHERS_PRESURVEY_STATUS = 'GET_TEACHERS_PRESURVEY_STATUS';
export const GET_TEACHERS_BY_ID = 'GET_TEACHERS_BY_ID';

export const MENTORS_SUPPORT_TICKETS_STATUS = 'MENTORS_SUPPORT_TICKETS_STATUS';

export const MENTORS_EDIT = 'MENTORS_EDIT';
export const MENTORS_EDIT_SUCCESS = 'MENTORS_EDIT_SUCCESS';
export const MENTORS_EDIT_ERROR = 'MENTORS_EDIT_ERROR';

// Modules //

export const MODULES_LIST = 'MODULES_LIST';
export const MODULES_LIST_SUCCESS = 'MODULES_LIST_SUCCESS';
export const MODULES_LIST_ERROR = 'MODULES_LIST_ERROR';

/* Admin */
export const GET_ADMINS = 'GET_ADMINS';

export const ADMIN_LANGUAGE = 'ADMIN_LANGUAGE';
export const ADMIN_LOGIN_USER = 'ADMIN_LOGIN_USER';
export const ADMIN_LOGIN_USER_SUCCESS = 'ADMIN_LOGIN_USER_SUCCESS';
export const ADMIN_LOGIN_USER_ERROR = 'ADMIN_LOGIN_USER_ERROR';

export const ADMIN_LIST_SUCCESS = 'ADMIN_LIST_SUCCESS';
export const ADMIN_LIST_ERROR = 'ADMIN_LIST_ERROR';

export const ADMIN_LOGIN_USER_LOGOUT = 'ADMIN_LOGIN_USER_LOGOUT';
export const ADMIN_LOGIN_USER_LOGOUT_SUCCESS =
    'ADMIN_LOGIN_USER_LOGOUT_SUCCESS';
export const ADMIN_LOGIN_USER_LOGOUT_ERROR = 'ADMIN_LOGIN_USER_LOGOUT_ERROR';

export const ADMIN_COURSES_LIST = 'ADMIN_COURSES_LIST';
export const ADMIN_COURSES_LIST_SUCCESS = 'ADMIN_COURSES_LIST_SUCCESS';
export const ADMIN_COURSES_LIST_ERROR = 'ADMIN_COURSES_LIST_ERROR';

export const ADMIN_COURSES_DETAILS = 'ADMIN_COURSES_DETAILS';
export const ADMIN_COURSES_DETAILS_SUCCESS = 'ADMIN_COURSES_DETAILS_SUCCESS';
export const ADMIN_COURSES_DETAILS_ERROR = 'ADMIN_COURSES_DETAILS_ERROR';

export const ADMIN_COURSES_QUESTIONS = 'ADMIN_COURSES_QUESTIONS';
export const ADMIN_COURSES_QUESTIONS_SUCCESS =
    'ADMIN_COURSES_QUESTIONS_SUCCESS';
export const ADMIN_COURSES_QUESTIONS_ERROR = 'ADMIN_COURSES_QUESTIONS_ERROR';

export const ADMIN_COURSES_QUESTIONS_RESPONCE =
    'ADMIN_COURSES_QUESTIONS_RESPONCE';
export const ADMIN_COURSES_QUESTIONS_RESPONCE_SUCCESS =
    'ADMIN_COURSES_QUESTIONS_RESPONCE_SUCCESS';
export const ADMIN_COURSES_QUESTIONS_RESPONCE_ERROR =
    'ADMIN_COURSES_QUESTIONS_RESPONCE_ERROR';

export const ADMIN_COURSES_REF_QUESTIONS_RESPONCE =
    'ADMIN_COURSES_REF_QUESTIONS_RESPONCE';
export const ADMIN_COURSES_REF_QUESTIONS_RESPONCE_SUCCESS =
    'ADMIN_COURSES_REF_QUESTIONS_RESPONCE_SUCCESS';
export const ADMIN_COURSES_REF_QUESTIONS_RESPONCE_ERROR =
    'ADMIN_COURSES_REF_QUESTIONS_RESPONCE_ERROR';

export const ADMIN_COURSES_REF_QUESTIONS = 'ADMIN_COURSES_REF_QUESTIONS';
export const ADMIN_COURSES_REF_QUESTIONS_SUCCESS =
    'ADMIN_COURSES_REF_QUESTIONS_SUCCESS';
export const ADMIN_COURSES_REF_QUESTIONS_ERROR =
    'ADMIN_COURSES_REF_QUESTIONS_ERROR';

export const ADMIN_COURSES_CREATE = 'ADMIN_COURSES_CREATE';
export const ADMIN_COURSES_CREATE_SUCCESS = 'ADMIN_COURSES_CREATE_SUCCESS';
export const ADMIN_COURSES_CREATE_ERROR = 'ADMIN_COURSES_CREATE_ERROR';

export const ADMIN_MENTORS_LIST = 'ADMIN_MENTORS_LIST';
export const ADMIN_MENTORS_LIST_SUCCESS = 'ADMIN_MENTORS_LIST_SUCCESS';
export const ADMIN_MENTORS_LIST_ERROR = 'ADMIN_MENTORS_LIST_ERROR';
export const ADMIN_MENTORS_STATUS_UPDATE = 'ADMIN_MENTORS_STATUS_UPDATE';
export const ADMIN_MENTORS_PAGE_NUMBER = 'ADMIN_MENTORS_PAGE_NUMBER';
export const ADMIN_MENTORS_PAGE_SIZE = 'ADMIN_MENTORS_PAGE_SIZE';

export const ADMIN_EVALUTORS_LIST = 'ADMIN_EVALUTORS_LIST';
export const ADMIN_EVALUTORS_LIST_SUCCESS = 'ADMIN_EVALUTORS_LIST_SUCCESS';
export const ADMIN_EVALUTORS_LIST_ERROR = 'ADMIN_EVALUTORS_LIST_ERROR';

export const ADMIN_NOTIFICATIONS_LIST = 'ADMIN_NOTIFICATIONS_LIST';
export const ADMIN_NOTIFICATIONS_LIST_SUCCESS =
    'ADMIN_NOTIFICATIONS_LIST_SUCCESS';
export const ADMIN_NOTIFICATIONS_LIST_ERROR = 'ADMIN_NOTIFICATIONS_LIST_ERROR';
export const ADMIN_DELETE_TEMP_MENTOR = 'ADMIN_DELETE_TEMP_MENTOR';

//
export const SCHOOL_REGISTRATOION_BULKUPLOAD_LIST =
    'SCHOOL_REGISTRATOION_BULKUPLOAD_LIST';
export const SCHOOL_REGISTRATOION_BULKUPLOAD_LIST_SUCCESS =
    'SCHOOL_REGISTRATOION_BULKUPLOAD_LIST_SUCCESS';
export const SCHOOL_REGISTRATOION_BULKUPLOAD_LIST_ERROR =
    'SCHOOL_REGISTRATOION_BULKUPLOAD_LIST_ERROR';

//
export const EVALUATORS_BULKUPLOAD_LIST = 'EVALUATORS_BULKUPLOAD_LIST';
export const EVALUATORS_BULKUPLOAD_LIST_SUCCESS =
    'EVALUATORS_BULKUPLOAD_LIST_SUCCESS';
export const EVALUATORS_BULKUPLOAD_LIST_ERROR =
    'EVALUATORS_BULKUPLOAD_LIST_ERROR';

/* Admin */
export const TEAM_MEMBER_STATUS = 'TEAM_MEMBER_STATUS';
export const TEAM_MEMBER_STATUS_ERROR = 'TEAM_MEMBER_STATUS_ERROR';
export const TEACHER_LOGIN_USER = 'TEACHER_LOGIN_USER';
export const TEACHER_LOGIN_USER_SUCCESS = 'TEACHER_LOGIN_USER_SUCCESS';
export const TEACHER_LOGIN_USER_ERROR = 'TEACHER_LOGIN_USER_ERROR';
export const TEACHER_LOGIN_USER_LOGOUT = 'TEACHER_LOGIN_USER_LOGOUT';
export const TEACHER_LOGIN_USER_LOGOUT_SUCCESS =
    'TEACHER_LOGIN_USER_LOGOUT_SUCCESS';
export const TEACHER_LOGIN_USER_LOGOUT_ERROR =
    'TEACHER_LOGIN_USER_LOGOUT_ERROR';
export const TEACHER_DASHBOARD_STATES_SUCCESS =
    'TEACHER_DASHBOARD_STATES_SUCCESS';

export const ADMIN_TEAMS_LIST = 'ADMIN_TEAMS_LIST';
export const ADMIN_TEAMS_LIST_SUCCESS = 'ADMIN_TEAMS_LIST_SUCCESS';
export const ADMIN_TEAMS_LIST_ERROR = 'ADMIN_TEAMS_LIST_ERROR';
export const ADMIN_TEAMS_MEMBERS_LIST = 'ADMIN_TEAMS_MEMBERS_LIST';
export const ADMIN_TEAMS_MEMBERS_LIST_SUCCESS =
    'ADMIN_TEAMS_MEMBERS_LIST_SUCCESS';
export const ADMIN_TEAMS_MEMBERS_LIST_ERROR = 'ADMIN_TEAMS_MEMBERS_LIST_ERROR';

export const TEACHER_COURSES_DETAILS = 'TEACHER_COURSES_DETAILS';
export const TEACHER_COURSES_ATTACHMENTS = 'TEACHER_COURSES_ATTACHMENTS';
export const TEACHER_COURSES_DETAILS_SUCCESS =
    'TEACHER_COURSES_DETAILS_SUCCESS';
export const TEACHER_COURSES_DETAILS_ERROR = 'TEACHER_COURSES_DETAILS_ERROR';

export const EVALUATOR_LOGIN_USER = 'EVALUATOR_LOGIN_USER';
export const EVALUATOR_LOGIN_USER_SUCCESS = 'EVALUATOR_LOGIN_USER_SUCCESS';
export const EVALUATOR_LOGIN_USER_ERROR = 'EVALUATOR_LOGIN_USER_ERROR';
export const GET_SUBMITTED_IDEA_LIST = 'GET_SUBMITTED_IDEA_LIST';
export const GET_INSTRUCTIONS = 'GET_INSTRUCTIONS';
export const GET_L1_EVALUATED_IDEA = 'GET_L1_EVALUATED_IDEA';
export const EVALUATOR_ADMIN_LOGIN_USER = 'EVALUATOR_ADMIN_LOGIN_USER';
export const EVALUATOR_ADMIN_LOGIN_USER_SUCCESS =
    'EVALUATOR_ADMIN_LOGIN_USER_SUCCESS';
export const EVALUATOR_ADMIN_LOGIN_USER_ERROR =
    'EVALUATOR_ADMIN_LOGIN_USER_ERROR';
export const UPDATAE_EVALUATOR = 'UPDATE_EVALUATOR';

export const SCHOOL_LOGIN_USER = 'SCHOOL_LOGIN_USER';
export const SCHOOL_LOGIN_USER_SUCCESS = 'SCHOOL_LOGIN_USER_SUCCESS';
export const SCHOOL_LOGIN_USER_ERROR = 'SCHOOL_LOGIN_USER_ERROR';
export const GET_SCHOOL_BY_ID = 'GET_SCHOOL_BY_ID';

export const COORDINATOR_LOGIN_USER = 'COORDINATOR_LOGIN_USER';
export const COORDINATOR_LOGIN_USER_SUCCESS = 'COORDINATOR_LOGIN_USER_SUCCESS';
export const COORDINATOR_LOGIN_USER_ERROR = 'COORDINATOR_LOGIN_USER_ERROR';

export const REPORT_LOGIN_USER = 'REPORT_LOGIN_USER';
export const REPORT_LOGIN_USER_SUCCESS = 'REPORT_LOGIN_USER_SUCCESS';
export const REPORT_LOGIN_USER_ERROR = 'REPORT_LOGIN_USER_ERROR';

export * from './auth/actions';
export * from '../School/store/school/actions';
export * from '../Coordinators/store/Coordinator/actions';
export * from '../ReportsPanel/store/actions';
export * from './Institution/actions';

export * from '../Admin/store/admin/actions';
export * from '../Teachers/store/mentors/actions';
export * from './modules/actions';
export * from './sample/actions';
export * from '../Admin/Courses/store/adminCourses/actions';
export * from '../Admin/store/adminEvalutors/actions';
export * from '../Admin/store/adminMentors/actions';
export * from '../Admin/store/adminNotifications/actions';
export * from './schoolRegistration/actions';
export * from './evaluatorsBulkUpload/actions';
export * from '../Teachers/store/teacher/actions';
export * from '../Teachers/store/teams/actions';
export * from '../Teachers/store/courses/actions';

export * from '../Evaluator/store/evaluator/action';
